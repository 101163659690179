function Button(props) {
  const { link, icon} = props
  return (
    <div className="bg-[#F9FBF2] drop-shadow-lg hover:bg-[#F9FBF280] rounded-lg w-10 h-10 flex justify-center items-center ease-in-out duration-300">
      <a href={link} target="_blank" rel="noopener noreferrer" className=" text-[#0E1C36] text-2xl">
        <i class={`${icon}`}></i>
      </a>
    </div>
  )
}

export default Button;